<template>
  <div>
    <!-- Navigation -->
    <DesktopSidebar />
    <BottomNavBar />
    <CommandPalette />
    <DefaultModalContainer />

    <div
      :class="$appStore && $appStore.isSidebarLarge ? 'lg:pl-72' : 'lg:pl-16'"
      class="h-full transition-all duration-200"
    >
      <!-- Page content -->
      <slot />
    </div>
  </div>
</template>

<script setup>
import {useRuntimeConfig} from "#app";

useHead({
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
  charset: 'utf-8',
  meta: [{ name: 'description', content: 'My amazing site.' }],
  bodyAttrs: {
    class: 'h-full bg-gray-50 dark:bg-black'
  },
  htmlAttrs: {
    class: 'h-full'
  }
})

defineAppShortcuts()
const { $appStore, $budgetStore, $userStore, $areaStore } = useNuxtApp()

if($userStore.id && (!$budgetStore.budget.id || !$areaStore.budget_id || $budgetStore.budget.id !== $areaStore.budget_id)) {
  await $budgetStore.getActiveBudget()
  $areaStore.budget_id = $budgetStore.budget.id
}
</script>
