<script setup lang="ts">
const { $budgetStore } = useNuxtApp()

const items = [{
  label: '- Outflow',
}, {
  label: '+ Inflow',
}]

const amount = ref()

//computed property to determine if amount contains a minus sign
const isNegative = computed(() => {
  if(amount.value) {
    return amount.value.includes('-')
  }

  return selectedTab.value === 0;
})


const selectedTab = ref(0)
const inputAmount = ref()

// watch for changes in amount, and if it is negative, set the selectedTab to 0
watch(amount, (value) => {
  if(selectedTab.value === 0) {
    if (!value.includes('-') && amount.value.length === 1) {
      amount.value = '-' + value
    }
  }

  if (value.includes('-')) {
    selectedTab.value = 0
  } else {
    selectedTab.value = 1
  }
})

// watch for changes in selectedTab, and if it is 0, add a minus sign to amount
watch(selectedTab, (value) => {
  if (value === 0 && amount.value) {
    if (!amount.value.includes('-') && amount.value.length > 0) {
      amount.value = '-' + amount.value

      //trigger inputAmount input event
      inputAmount.value.$el.dispatchEvent(new Event('input'))
    }
  } else if (value === 1 && amount.value) {
    amount.value = amount.value.replace('-', '')
    //trigger inputAmount input event
    inputAmount.value.$el.dispatchEvent(new Event('input'))
  }
})
</script>

<template>
  <UTabs v-model="selectedTab" :items="items" :ui="{ list: { background: 'bg-transparent dark:bg-transparent' }}" />

  <div class="px-4 py-6">
    <div class="flex items-center justify-center">
      <div class="max-w-[80%] overflow-hidden">
        <UInput
            ref="inputAmount"
            v-model="amount"
            variant="titleAmount"
            inputmode="decimal"
            v-amount
            v-auto-width="'text-4xl'"
            autofocus
            :ui="{ base: isNegative ? '!text-red-500 dark:!text-red-400' : '!text-green-500 dark:!text-green-400' }"
            :placeholder="`${selectedTab === 0 ? '-' : ''}0${$budgetStore.budget.currency.decimal_separator}00`"
        >
          <template
              v-if="$budgetStore.budget.currency.symbol_placement === 'before'"
              #leading
          >
            <span class="text-3xl opacity-75">{{ $budgetStore.budget.currency.symbol }}</span>
          </template>
          <template
              v-else-if="$budgetStore.budget.currency.symbol_placement === 'after'"
              #trailing
          >
            <span class="text-3xl opacity-75">{{ $budgetStore.budget.currency.symbol }}</span>
          </template>
        </UInput>
      </div>
    </div>
    <div class="flex flex-col sm:ml-2 sm:items-start text-center sm:text-left mt-4">

    </div>
    <div class="mt-4 flex gap-x-3 flex-row-reverse justify-start">
      <UButton variant="solid" color="primary">Add</UButton>
      <UButton variant="ghost" color="white">Cancel</UButton>
    </div>
  </div>
</template>

<style scoped>

</style>