export const colorPreference = computed(() => {
  const colorMode = useColorMode()
  return colorMode.preference.charAt(0).toUpperCase() + colorMode.preference.slice(1) + ' Mode'
})

export const colorPreferenceIcon = computed(() => {
  const colorMode = useColorMode()
  let icon = 'fa-light fa-moon'
  if (colorMode.preference === 'system') {
    icon = 'fa-light fa-display'
  } else if (colorMode.preference === 'light') {
    icon = 'fa-light fa-sun-bright'
  }

  return icon
})

export function setColorMode (mode: null|'dark'|'light'|'system' = null) {
  const colorMode = useColorMode()
  if (!mode) {
    if (colorMode.preference === 'system') {
      colorMode.preference = 'dark'
    } else if (colorMode.preference === 'dark') {
      colorMode.preference = 'light'
    } else if (colorMode.preference === 'light') {
      colorMode.preference = 'system'
    }
  } else {
    colorMode.preference = mode
  }
}
