<script setup lang="ts">
const navigationItems = getNavigationItems('mobile')
const route = useRoute()
const appConfig = useAppConfig()
const { $userStore, $appStore } = useNuxtApp()
</script>

<template>
  <div class="fixed lg:hidden bottom-0 left-0 z-50 h-14 w-full shadow-md flex items-center justify-center">
    <div
      class="flex items-center justify-between space-x-4 w-full h-full ring-1 ring-[#E8E8EB] dark:ring-[#18181B]"
      :class="[appConfig.ui.popover.background, appConfig.ui.popover.shadow]"
    >
      <div class="h-fit w-fit p-1 flex justify-center items-center">
        <SDropdown
          class="h-full"
          :items="navigationItems.menu.value"
          :ui="{ item: { disabled: 'hidden' }, width: 'sm:w-60', base: 'overflow-visible mb-2' }"
          :popper="{ placement: 'top-end', strategy: 'absolute' }"
        >
          <span
            :class="[[appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400'],
                     'group flex flex-col items-center text-xs font-normal transition-all duration-150 ease-in-out w-12 h-12 justify-center rounded-lg']"
          >
            <AssetIconEnvolop :svg-class="'h-4 w-4'" />
          </span>
          <template #account>
            <div class="text-left text-sm w-full opacity-50">
              <p class="text-xs opacity-70">
                Signed in as
              </p>
              <p class="truncate font-medium text-gray-900 dark:text-white">
                {{ $userStore.email }}
              </p>
            </div>
          </template>
        </SDropdown>
      </div>
      <transition name="slide-fade">
        <div
          v-show="!$appStore.isBottomBarHidden"
          class="flex-grow p-1 h-fit w-fit overflow-none"
        >
          <nav class="flex gap-x-1 h-full">
            <div
              v-for="(item) in navigationItems.navigation"
              :key="item.name"
              class="flex-1 h-full"
            >
              <NuxtLink
                :id="'mobile-' + item.id"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.routeName === route.name ? 'bg-gray-300/60 dark:bg-gray-700/60 text-gray-900 dark:text-gray-100' :
                  [appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400'],
                  'group flex flex-row items-center text-xs font-normal transition-all duration-150 ease-in-out w-full h-12 justify-center rounded-lg',
                ]"
                :aria-current="item.routeName === route.name ? 'page' : undefined"
              >
                <fa
                  :icon="item.icon"
                  :class="['text-lg']"
                />
                <span class="hidden sm:inline ml-3 !text-sm">{{ item.name }}</span>
              </NuxtLink>
            </div>
            <div class="flex-1 h-full">
              <SDropdown
                class="h-full w-full"
                :items="navigationItems.accountsMenu || []"
                mode="click"
                :ui="{ width: 'w-44', base: 'mb-3' }"
                :popper="{ placement: 'top-end', strategy: 'absolute' }"
              >
                <span
                  :class="[[appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400'],
                           'group flex flex-row items-center text-xs font-normal transition-all duration-150 ease-in-out w-full h-12 justify-center rounded-lg']"
                >
                  <fa
                    icon="fa-light fa-money-bill-transfer"
                    :class="['text-lg']"
                  />
                  <span class="hidden sm:inline ml-3 !text-sm">Accounts</span>
                </span>
              </SDropdown>
            </div>
            <div class="flex-1 h-full">
              <SDropdown
                class="h-full w-full"
                :items="navigationItems.addItems"
                mode="click"
                :ui="{ width: 'w-44', base: 'mb-3' }"
                :popper="{ placement: 'top-end', strategy: 'absolute' }"
              >
                <span
                  :class="[[appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400'],
                           'group flex flex-row items-center text-xs font-normal transition-all duration-150 ease-in-out w-full h-12 justify-center rounded-lg']"
                >
                  <fa
                    icon="fa-regular fa-plus"
                    :class="['text-lg']"
                  />
                  <span class="hidden sm:inline ml-3 !text-sm">Add</span>
                </span>
              </SDropdown>
            </div>
          </nav>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all ease-in-out .2s;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(25%);
  opacity: 0;
}
</style>
