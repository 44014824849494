import { setColorMode } from '~/composables/ColorMode'

export function defineAppShortcuts () {
  defineShortcuts({
    meta_k: {
      usingInput: true,
      handler: () => {
        setState('commandPalette', 'on')
      }
    },
    'meta_shift_,': {
      usingInput: true,
      handler: () => {
        alert('Settings')
      }
    },
    m: {
      usingInput: false,
      handler: () => {
        setColorMode()
      }
    },
    n: {
      usingInput: false,
      handler: () => {
        setState('addTransaction')
      }
    },
    c: {
      usingInput: false,
      handler: () => {
        setState('addAreaCategory')
      }
    },
    l: {
      usingInput: false,
      handler: () => {
        setBudgetState('layoutPicker')
      }
    },
    a: {
      usingInput: false,
      handler: () => {
        alert('New account')
      }
    },
    h: {
      usingInput: false,
      handler: () => {
        navigateTo('/')
      }
    },
    b: {
      usingInput: false,
      handler: () => {
        navigateTo('/budget')
      }
    },
    t: {
      usingInput: false,
      handler: () => {
        alert('Transactions')
      }
    }
  })
}
