<script setup lang="ts">
import * as yup from "yup"
const { $areaStore } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    required: false
  }
})

const areaState = reactive({
  areaName: '',
})

const areaSchema = yup.object({
  areaName: yup.string().required('Required'),
})

function submitArea() {
  $areaStore.addArea(areaState.areaName)
  setState('addArea', 'off')
}
</script>

<template>
  <div>
      <div class="px-4 py-2">
        <div class="flex flex-col sm:items-start mt-2">
          <UForm
              :schema="areaSchema"
              :state="areaState"
              :validate-on="['submit']"
              class="space-y-6 w-full"
              @submit="submitArea"
          >
            <div class="flex flex-col gap-5">
              <UFormGroup
                  label="Area name"
                  name="areaName"
                  class="w-full"
              >
                <UInput
                    id="areaName"
                    size="lg"
                    v-model="areaState.areaName"
                    name="areaName"
                    autofocus
                    placeholder="Monthly bills"
                />
              </UFormGroup>
            </div>

            <div class="pb-2 mt-4 flex gap-x-3 flex-row-reverse justify-start">
              <UButton type="submit" variant="solid" color="primary">Add area</UButton>
              <UButton @click="setState('addArea', 'off')" variant="ghost" color="white">Cancel</UButton>
            </div>
          </UForm>
        </div>
      </div>
  </div>
</template>

<style scoped>

</style>