<script setup lang="ts">
import type {PropType} from "vue";

type MenuItem = {
  id: string
  name: string
  href: string
  icon?: string
  routeName?: string
  current?: boolean
  shortcuts?: any
  [x: string]: any;
}

const props = defineProps({
  item: {
    type: Object as PropType<MenuItem>|PropType<any>,
    required: true
  },
  active: {
    type: Boolean,
    default: false
  },
  isSidebarLarge: {
    type: Boolean,
    default: false
  }
})

const appConfig = useAppConfig()
</script>

<template>
  <UTooltip
      :text="item.name"
      :popper="{ placement: 'right' }"
      :prevent="isSidebarLarge"
      class="flex flex-col"
      :shortcuts="item.shortcuts"
  >
    <NuxtLink
        :id="item.id"
        :key="item.name"
        :to="item.href"
        :aria-current="active ? 'page' : undefined"
        :class="[active ?
          (!isSidebarLarge ? 'bg-gray-300/60 dark:bg-gray-700/60 text-gray-900 dark:text-gray-100' : 'text-black dark:text-white font-semibold') :
          (!isSidebarLarge ? [appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400'] : 'text-gray-500/75 hover:text-gray-600 dark:text-gray-500/90 dark:hover:text-gray-400'),
          'group flex gap-x-4 rounded-lg text-sm leading-6 items-center !outline-none focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
          isSidebarLarge ? 'p-2 -ml-2' : ' w-12 h-12 justify-center']"
    >
      <slot :active="active" />
    </NuxtLink>
  </UTooltip>
</template>

<style scoped>

</style>