

const bottomBarToggleText = computed(() => {
  const { $appStore } = useNuxtApp()
  return $appStore.isBottomBarHidden ? 'Show navigation' : 'Hide navigation'
})

export const getNavigationItems = (type: 'mobile'|'default' = 'default') => {
  const { isMacOS } = useDevice()
  const metaSymbolEx = isMacOS ? '⌘' : 'Ctrl'
  const { $userStore, $appStore } = useNuxtApp()

  const navigation = [
    { name: 'Home', id: 'nav-home', href: '/', icon: 'fa-light fa-house-blank', shortcuts: ['H'], routeName: 'index' },
    { name: 'Budget', id: 'nav-budget', href: '/budget', icon: 'fa-light fa-envelope-open', shortcuts: ['B'], routeName: 'budget' },
    ...(type === 'default'
      ? [{ name: 'Transactions', id: 'nav-transactions', href: '#', icon: 'fa-light fa-money-bill-transfer', shortcuts: ['T'], routeName: 'dsad' }]
      : [])
  ]

  const accounts = [
    { id: 1, name: 'Brukskonto', href: '#', shortcuts: ['1'], current: false },
    { id: 2, name: 'Sparekonto', href: '#', shortcuts: ['2'], current: false },
    { id: 3, name: 'Aksjekonto', href: '#', shortcuts: ['3'], current: false }
  ]

  // @ts-ignore
  const menu = ref([
    [{
      label: $userStore.email,
      disabled: false
    }],

    [{
      label: 'Settings',
      icon: 'fa-light fa-gear',
      shortcuts: [metaSymbolEx, 'Shift', ',']
    },  {
      label: colorPreference,
      icon: colorPreferenceIcon,
      shortcuts: ['M'],
      click: (): undefined => {
        setColorMode()
      }
    }, {
      label: 'Budget layout',
      icon: 'fa-light fa-table-layout',
      shortcuts: ['L'],
      click: (): undefined => {
        setBudgetState('layoutPicker', 'on')
      }
    }, {
      label: type === 'default' ? 'Toggle sidebar' : bottomBarToggleText,
      icon: 'fa-light fa-sidebar',
      shortcuts: type === 'default' ? ['<'] : [],
      click: () => {
        type === 'default' ? $appStore.toggleSidebarSize() : $appStore.toggleBottomBar()
      }
    }],

    [{
      label: 'Changelog',
    }, {
      label: 'Status',
    }, {
      label: 'Search',
      icon: 'i-heroicons-magnifying-glass',
      disabled: type === 'default',
      click: () => {
        setState('commandPalette', 'on')
      }
    }],

    [{
      label: 'Log out',
      icon: 'fa-light fa-arrow-right-from-bracket',
      click: () => {
        $userStore.logout()
      }
    }]
  ])

  const addItems = [
    [{
      label: 'Transaction',
      icon: 'fa-regular fa-plus',
      shortcuts: ['N'],
      click: () => {
        setState('addTransaction', 'on')
      }
    }, {
      label: 'Import',
      icon: 'fa-regular fa-plus',
      shortcuts: ['I']
    }],
    [{
      label: 'Account',
      icon: 'fa-regular fa-plus',
      shortcuts: ['A']
    }],
    [{
      label: 'Category',
      icon: 'fa-regular fa-plus',
      shortcuts: ['C'],
      click: () => {
        setState('addCategory', 'on')
      }
    }, {
      label: 'Area',
      icon: 'fa-regular fa-plus',
      click: () => {
        setState('addArea', 'on')
      }
    }]
  ]

  let accountsMenu = null
  if (type === 'mobile') {
    accountsMenu = [accounts.flatMap(account => ({
      label: account.name,
      shortcuts: account.shortcuts,
      click: () => {
        alert(`Account ${account.name}`)
      }
    })),
    [{
      label: 'All Transactions',
      icon: 'fa-light fa-money-bill-transfer'
    }]]
  }

  return {
    navigation,
    accounts,
    menu,
    addItems,
    accountsMenu
  }
}
