<template>
  <TransitionRoot
    :show="isCommandOpen"
    class="z-50"
    as="template"
    appear
  >
    <Dialog
      as="div"
      class="relative z-50"
      @close="setState('commandPalette', 'off')"
    >
      <div class="fixed top-0 z-50 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-150"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-150"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="mx-auto max-w-2xl transform overflow-hidden rounded-xl bg-gray-50/80 dark:bg-gray-900/80 border border-gray-950/10 dark:border-gray-50/[7%] backdrop-blur-xl transition-all shadow-command dark:shadow-2xl">
            <UCommandPalette
              ref="commandPaletteRef"
              :groups="groups"
              :autoselect="true"
              :autoclear="false"
              placeholder="Search..."
              :empty-state="{ icon: 'i-heroicons-magnifying-glass-20-solid', label: 'Search for transactions, accounts or commands.', queryLabel: 'We couldn\'t find any items with that term. Please try again.' }"
              :fuse="{
                fuseOptions: {
                  threshold: 0.45,
                  keys: ['label', 'prefix', 'suffix', 'inactive']
                },
                resultLimit: 10
              }"
              @update:model-value="onMenuSelect"
            >
              <template #transactions-icon="{ command, active }">
                <fa
                  v-if="command.icon"
                  :icon="command.icon"
                  :class="[appConfig.ui.commandPalette.group.command.icon.base, active ? appConfig.ui.commandPalette.group.command.icon.active : appConfig.ui.commandPalette.group.command.icon.inactive, command.iconClass]"
                  aria-hidden="true"
                />
                <UAvatar
                  v-else-if="command.avatar"
                  v-bind="{ size: appConfig.ui.commandPalette.group.command.avatar.size, ...command.avatar }"
                  :class="appConfig.ui.commandPalette.group.command.avatar.base"
                  aria-hidden="true"
                />
                <span
                  v-else-if="command.chip"
                  :class="appConfig.ui.commandPalette.group.command.chip.base"
                  :style="{ background: `#${command.chip}` }"
                />
              </template>

              <template #commands-icon="{ command, active }">
                <fa
                  v-if="command.icon"
                  :icon="command.icon"
                  :class="[appConfig.ui.commandPalette.group.command.icon.base, active ? appConfig.ui.commandPalette.group.command.icon.active : appConfig.ui.commandPalette.group.command.icon.inactive, command.iconClass]"
                  aria-hidden="true"
                />
                <UAvatar
                  v-else-if="command.avatar"
                  v-bind="{ size: appConfig.ui.commandPalette.group.command.avatar.size, ...command.avatar }"
                  :class="appConfig.ui.commandPalette.group.command.avatar.base"
                  aria-hidden="true"
                />
                <span
                  v-else-if="command.chip"
                  :class="appConfig.ui.commandPalette.group.command.chip.base"
                  :style="{ background: `#${command.chip}` }"
                />
              </template>

              <template #transactions-inactive="{command}">
                <span class="flex-shrink-0 text-gray-800/50 dark:text-gray-200/40">{{ command.inactive }}</span>
              </template>

              <template #transactions-command="{command, active}">
                <span
                  v-if="command.prefix"
                  :class="appConfig.ui.commandPalette.group.command.prefix"
                  class="truncate max-w-[10rem]"
                >{{ command.prefix }}</span>
                <div v-if="command.prefix">
                  <fa icon="fa-regular fa-chevron-right" class="ml-1.5 mr-2 text-xs" :class="active ? appConfig.ui.commandPalette.group.command.arrow.active : appConfig.ui.commandPalette.group.command.arrow.inactive" />
                </div>

                <span class="truncate max-w-[55%]">{{ command.label }}</span>

                <div v-if="command.suffix">
                  <fa icon="fa-regular fa-chevron-right" class="ml-1.5 mr-2 text-xs" :class="active ? appConfig.ui.commandPalette.group.command.arrow.active : appConfig.ui.commandPalette.group.command.arrow.inactive" />
                </div>
                <span
                  v-if="command.suffix"
                  :class="appConfig.ui.commandPalette.group.command.suffix"
                  class="whitespace-nowrap mr-2"
                >{{ command.suffix }}</span>
              </template>

              <template #shortcuts-command="{command, active}">
                <span
                  v-if="command.prefix"
                  :class="appConfig.ui.commandPalette.group.command.prefix"
                  class="truncate max-w-[10rem]"
                >{{ command.prefix }}</span>
                <div v-if="command.prefix">
                  <fa icon="fa-regular fa-chevron-right" class="ml-1.5 mr-2 text-xs" :class="active ? appConfig.ui.commandPalette.group.command.arrow.active : appConfig.ui.commandPalette.group.command.arrow.inactive" />
                </div>

                <span>{{ command.label }}</span>

                <div v-if="command.suffix">
                  <fa icon="fa-regular fa-chevron-right" class="ml-1.5 mr-2 text-xs" :class="active ? appConfig.ui.commandPalette.group.command.arrow.active : appConfig.ui.commandPalette.group.command.arrow.inactive" />
                </div>
                <span
                  v-if="command.suffix"
                  :class="appConfig.ui.commandPalette.group.command.suffix"
                  class="whitespace-nowrap"
                >{{ command.suffix }}</span>
              </template>

              <template #empty-state />
            </UCommandPalette>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useFuse } from '@vueuse/integrations/useFuse'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {isCommandOpen, setState} from "~/composables/GlobalState";
const { $userStore } = useNuxtApp()
const { isMacOS } = useDevice()
const metaSymbolEx = isMacOS ? '⌘' : 'Ctrl'

const appConfig = useAppConfig()
const commandPaletteRef = ref()

function onMenuSelect (option) {
  if (option.click) {
    option.click()
  } else if (option.to) {
    navigateTo(option.to)
  } else if (option.href) {
    navigateTo(option.href, { external: true, target: '_blank' })
  }
  setState('commandPalette', 'off')
  commandPaletteRef.value.query = ''
}

const transactions = [
  { id: '1', prefix: 'Brukskonto 123', label: 'Spar Haga', inactive: '23.03.2022', suffix: '300 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '2', prefix: 'Brukskonto', label: 'Power', inactive: '13.04.2022', suffix: '200 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '3', prefix: 'Brukskonto', label: 'Rema 1000', inactive: '20.04.2022', suffix: '199 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '4', prefix: 'Sparekonto', label: 'Bunnpris', inactive: '22.04.2022', suffix: '43 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '5', prefix: 'Aksjekonto', label: 'Kafe Jordalen', inactive: '22.04.2022', suffix: '302 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '6', prefix: 'Brukskonto', label: 'Power', inactive: '11.04.2023', suffix: '1302 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '7', prefix: 'Brukskonto', label: 'ISS Kafe', inactive: '01.05.2023', suffix: '500 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '8', prefix: 'Sparekonto', label: 'Laravel Forge', inactive: '22.06.2023', suffix: '60 kr', icon: 'fa-light fa-money-bill-transfer' },
  { id: '9', prefix: 'Aksjekonto', label: 'Amazon Web Services', inactive: '22.07.2023', suffix: '29 kr', icon: 'fa-light fa-money-bill-transfer' }
]

const commands = [
  { id: 'add-transaction', label: 'Add transaction...', queries: ['commands'], icon: 'fa-light fa-plus' },
  { id: 'import-transactions', label: 'Import transactions...', queries: ['commands'], icon: 'fa-light fa-plus' },
  { id: 'add-account', label: 'Add account...', queries: ['commands'], icon: 'fa-light fa-plus' },
  { id: 'dark-mode', label: 'Set theme to dark mode', queries: ['color', 'dark', 'commands'], icon: 'fa-light fa-moon', click: () => setColorMode('dark') },
  { id: 'light-mode', label: 'Set theme to light mode', queries: ['color', 'dark', 'light', 'commands'], icon: 'fa-light fa-sun-bright', click: () => setColorMode('light') },
  { id: 'system-mode', label: 'Set theme to follow system', queries: ['color', 'dark', 'system', 'mode', 'commands'], icon: 'fa-light fa-display', click: () => setColorMode('system') },
  { id: 'log-out', label: 'Log out', icon: 'fa-light fa-arrow-right-from-bracket', click: () => $userStore.logout() },
  { id: 'settings', label: 'Settings', queries: ['commands'], icon: 'fa-light fa-gear', shortcuts: [metaSymbolEx, 'Shift', ','], click: () => alert('Settings') }
]

const groups = [
  {
    key: 'transactions',
    label: 'Transactions',
    // eslint-disable-next-line require-await
    search: async (q) => {
      if (!q) {
        return []
      }

      const options = {
        fuseOptions: {
          threshold: 0.45,
          keys: ['label', 'prefix', 'suffix', 'inactive', 'queries']
        },
        resultLimit: 6
      }

      const results = useFuse(q, transactions, options).results.value

      return results.map(result => ({
        id: result.item.id,
        label: result.item.label,
        prefix: result.item.prefix,
        suffix: result.item.suffix,
        inactive: result.item.inactive,
        icon: result.item.icon,
        shortcuts: result.item.shortcuts,
        click: result.item.click
      }))
    }
  },
  {
    key: 'commands',
    label: 'Commands',
    // eslint-disable-next-line require-await
    search: async (q) => {
      if (!q) {
        return []
      }

      const options = {
        fuseOptions: {
          threshold: 0.45,
          keys: ['label', 'queries']
        },
        resultLimit: 4
      }

      const results = useFuse(q, commands, options).results.value

      return results.map(result => ({
        id: result.item.id,
        label: result.item.label,
        icon: result.item.icon,
        shortcuts: result.item.shortcuts,
        click: result.item.click
      }))
    }
  }
]
</script>
