<script setup lang="ts">
import {RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption} from "@headlessui/vue";

const { $budgetStore } = useNuxtApp()

const layouts = [
  { id: 'card-columns', name: 'Card Columns', description: 'Areas are separated into vertical boards, with categories going top to bottom.', icon: 'fa-thin fa-grip-vertical' },
  { id: 'card-rows', name: 'Card Rows', description: 'Areas are separated into horizontal boards, with categories going left to right.', icon: 'fa-thin fa-grip-horizontal' },
  { id: 'list', name: 'List', description: 'Areas and categories are separated into lists.', icon: 'fa-thin fa-bars' }
]

const selected = ref(layouts[0])

if ($budgetStore.layout === 'card-rows') {
  selected.value = layouts[1]
} else if ($budgetStore.layout === 'list') {
  selected.value = layouts[2]
}

function handleSave() {
  $budgetStore.editBudgetLayout(selected.value.id as 'card-columns' | 'card-rows' | 'list')
  setBudgetState('layoutPicker', 'off')
}
</script>

<template>
  <div class="px-4 py-4">
    <div class="flex flex-col">
      <RadioGroup class="w-full" v-model="selected">
        <RadioGroupLabel class="sr-only">Budget layout</RadioGroupLabel>
        <div class="space-y-3">
          <RadioGroupOption as="template" v-for="(layout) in layouts" @keydown.enter="handleSave()" :key="layout.name" :value="layout" v-slot="{ active, checked }">
            <div :class="[checked ? 'bg-gray-300/75 dark:bg-gray-800/75' : 'bg-gray-200/40 hover:bg-gray-200/75 dark:bg-gray-900/70 dark:hover:bg-gray-900 focus-visible:bg-gray-200/75 dark:focus-visible:bg-gray-900', 'relative cursor-pointer rounded-lg px-6 py-4 outline-none flex justify-between duration-150 ease-in-out']">
          <span class="flex">
            <span class="flex flex-col text-sm">
              <span class="w-full flex flex-row justify-start">
                <fa :icon="layout.icon" class="text-4xl text-gray-500" />
              </span>
              <RadioGroupLabel as="span" class="font-medium text-gray-900 dark:text-gray-100 mt-6">{{ layout.name }}</RadioGroupLabel>
              <RadioGroupDescription as="span" class="text-gray-500">
                <span class="block sm:inline">{{ layout.description }}</span>
              </RadioGroupDescription>
            </span>
          </span>
              <span class="absolute top-3 right-5">
                <fa v-if="checked" icon="fa-light fa-check" class="w-fit text-lg" />
              </span>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>

    <div class="mt-4 flex gap-x-3 flex-row-reverse justify-start">
      <UButton variant="solid" color="primary" @click="handleSave">Save</UButton>
      <UButton variant="ghost" color="white" @click="setBudgetState('layoutPicker', 'off')">Cancel</UButton>
    </div>
  </div>
</template>

<style scoped>

</style>