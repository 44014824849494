<script setup lang="ts">
import * as yup from "yup"
const { $areaStore, $budgetStore } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    required: false
  }
})

const today = new Date()
const categoryState = reactive({
  categoryName: '',
  areaId: ref(props.data?.areaId || $areaStore.areas[0].id),
  goal: {
    type: 'nt',
    target: 0,
    frequency: 'monthly',
    due_by: 'last',
    repeat_every: 1,
    repeat_anchor: today,
    progress: 0,
    overall_progress: 0,
    overall_funded: 0,
    created: today
  } as Goal,
})

const categorySchema = yup.object({
  categoryName: yup.string().required('Required'),
  areaId: yup.number().required('Required'),
  goal: yup.object().shape({
    type: yup.string().required('Required').oneOf(['nt', 'spending', 'saving']),
    target: yup.number().required('Required'),
    frequency: yup.string().required('Required').oneOf(['weekly', 'monthly', 'yearly', 'once']),
    due_by: yup.string().required('Required'),
    repeat_every: yup.number().required('Required'),
    repeat_anchor: yup.date().required('Required'),
    progress: yup.number(),
    overall_progress: yup.number(),
    overall_funded: yup.number(),
    created: yup.date().required('Required'),
  })
})

const currentArea = computed(() => {
  return $areaStore.areas.find(area => area?.id === categoryState.areaId)
})

function updateCategoryGoal (goal: Goal) {
  categoryState.goal = goal
}

function submitCategory() {
  if(currentArea.value) {
    $areaStore.addCategory({
      id: 0,
      name: categoryState.categoryName,
      area_id: currentArea.value.id,
      budgeted: 0,
      balance: 0,
      spent: 0,
      goal: categoryState.goal
    }, currentArea.value.id)
    setState('addCategory', 'off')
  } else {
    throw new Error('Area not found')
  }
}
</script>

<template>
  <div>
      <div class="px-4 py-2">
        <div class="flex flex-col sm:items-start mt-2">
          <UForm
              :schema="categorySchema"
              :state="categoryState"
              :validate-on="['submit']"
              class="space-y-6 w-full"
              @submit="submitCategory"
          >
            <div class="flex flex-col gap-5">
              <UFormGroup
                  label="Category name"
                  name="categoryName"
                  class="w-full"
              >
                <UInput
                    id="categoryName"
                    size="lg"
                    v-model="categoryState.categoryName"
                    name="categoryName"
                    placeholder="Rent"
                    autofocus
                />
              </UFormGroup>
              <UFormGroup
                  label="Area"
                  name="areaId"
                  class="w-full"
              >
                <USelectMenu
                    searchable
                    searchable-placeholder="Search..."
                    v-model="categoryState.areaId"
                    name="areaId"
                    id="areaId"
                    :options="$areaStore.areas"
                    :ui="{ base: 'w-full' }"
                    class="w-full"
                    placeholder="Select category"
                    value-attribute="id"
                    option-attribute="name"
                    required
                >
                  <template #label>
                    <span>{{ currentArea.name }}</span>
                  </template>
                </USelectMenu>
              </UFormGroup>
            </div>

            <BudgetCardTarget
                @category-updated="(goal) => updateCategoryGoal(goal)"
                :new-category="true"
                :editing="true"
                :category="{
                    id: 0,
                    name: categoryState.categoryName,
                    area_id: currentArea.id,
                    budgeted: 0,
                    balance: 0,
                    spent: 0,
                    editing: false,
                    goal: categoryState.goal
                  } as Category"
                :currency="$budgetStore.budget.currency" />

            <div class="pb-2 mt-4 flex gap-x-3 flex-row-reverse justify-start">
              <UButton type="submit" variant="solid" color="primary">Add category</UButton>
              <UButton @click="setState('addCategory', 'off')" variant="ghost" color="white">Cancel</UButton>
            </div>
          </UForm>
        </div>
      </div>
  </div>
</template>

<style scoped>

</style>