<script setup lang="ts">
const { $userStore, $appStore, $budgetStore } = useNuxtApp()
const { metaSymbol } = useShortcuts()
const route = useRoute()
const appConfig = useAppConfig()
const navigationItems = getNavigationItems('default')

const sidebarRef = ref(null) // creating a ref
const isSidebarLarge = computed(() => $appStore.isSidebarLarge)

// Toggle sidebar size
const toggleSidebarSize = () => {
  $appStore.toggleSidebarSize()
}

defineShortcuts({
  '<': {
    usingInput: false,
    handler: () => {
      toggleSidebarSize()
    }
  }
})

// for each account in accounts, define a shortcut
navigationItems.accounts.forEach((account: Account) => {
  defineShortcuts({
    [account.shortcuts[0]]: {
      usingInput: false,
      handler: () => {
        alert(`Switch to ${account.name}`)
      }
    }
  })
})

const people = ['Wade Cooper', 'Arlene Mccoy', 'Devon Webb', 'Tom Cook', 'Tanya Fox', 'Hellen Schmidt', 'Caroline Schultz', 'Mason Heaney', 'Claudie Smitham', 'Emil Schaefer']
const selected = ref(people[0])
</script>

<template>
  <div
    ref="sidebarRef"
    class="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:flex-col duration-200 transition-all"
    :class="isSidebarLarge ? 'lg:w-72' : 'lg:w-16'"
  >
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      :class="isSidebarLarge ? 'gap-y-4 px-6' : 'gap-y-3 px-2'"
      class="flex grow flex-col no-scrollbar overflow-y-auto bg-gray-100 dark:bg-[#070709] ring-1 ring-[#E8E8EB] dark:ring-[#18181B]"
    >
      <div class="flex h-16 shrink-0 items-center">
        <div class="card w-full flex justify-content-center">
          <SDropdown
            :items="navigationItems.menu.value"
            :ui="{ item: { disabled: 'hidden' }, width: 'w-60', base: 'overflow-visible' }"
            :popper="{ placement: 'bottom-start' }"
          >
            <UTooltip
              :text="$budgetStore.budget.name"
              :popper="{ placement: 'right' }"
              :prevent="isSidebarLarge"
            >
              <UButton
                truncate
                color="white"
                variant="ghost"
                class="max-w-[10rem]"
                :class="isSidebarLarge ? 'mr-1' : ''"
                :ui="isSidebarLarge ? {} : { base: '!p-4' }"
              >
                <template #leading>
                  <AssetIconEnvolop :svg-class="isSidebarLarge ? 'mr-0.5 h-4 w-4' : 'h-4 w-4'" />
                </template>

                <span v-if="isSidebarLarge" class="truncate">{{ isSidebarLarge ? $budgetStore.budget.name : '' }}</span>
              </UButton>
            </UTooltip>
            <template #account>
              <div class="text-left text-sm w-full opacity-50">
                <p class="text-xs opacity-70">
                  Signed in as
                </p>
                <p class="truncate font-medium text-gray-900 dark:text-white">
                  {{ $userStore.email }}
                </p>
              </div>
            </template>

            <template #theme>
              <USelectMenu
                v-model="selected"
                :options="people"
              />
            </template>
          </SDropdown>
        </div>
        <div
          v-if="isSidebarLarge"
          class="flex flex-row gap-x-1"
        >
          <SDropdown
            :items="navigationItems.addItems"
            mode="hover"
            :ui="{ item: { disabled: 'cursor-default select-text' } }"
            :popper="{ placement: 'bottom' }"
          >
            <span tabindex="0" class="bg-transparent outline-none focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 rounded-lg h-9 w-9 flex justify-center items-center text-gray-900 dark:text-gray-100 hover:bg-gray-200/70 dark:hover:bg-gray-800/75 duration-150 ease-in-out cursor-pointer">
              <fa
                icon="fa-regular fa-plus"
                class="h-3.5 w-3.5"
              />
            </span>
          </SDropdown>
          <UTooltip
            text="Search"
            :shortcuts="[metaSymbol, 'K']"
          >
            <span
                tabindex="0"
              class="bg-transparent outline-none focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 rounded-lg h-9 w-9 flex justify-center items-center text-gray-900 dark:text-gray-100 hover:bg-gray-200/70 dark:hover:bg-gray-800/75 duration-150 ease-in-out cursor-pointer"
              @click="setState('commandPalette')"
              @keyup.enter="setState('commandPalette')"
              @keydown.space="setState('commandPalette')"
            >
              <fa
                icon="fa-regular fa-magnifying-glass"
                class="h-3.5 w-3.5"
              />
            </span>
          </UTooltip>
        </div>
      </div>

      <nav class="flex flex-1 flex-col" :class="isSidebarLarge && 'px-2'">
        <ul
          role="list"
          class="flex flex-1 flex-col gap-y-9"
        >
          <li>
            <ul
              role="list"
              class="flex flex-col gap-2"
            >
              <!-- Navigation blocks -->
              <li
                v-for="item in navigationItems.navigation"
                :key="item.name"
              >
                <SidebarItem
                    :item="item"
                    :active="route.name === item.routeName"
                    :isSidebarLarge="isSidebarLarge">
                  <template #default="{active}">
                    <fa
                        :icon="item.icon"
                        :class="[isSidebarLarge ? (active ? 'text-xl' : 'text-lg') : 'text-lg', 'h-6 w-6 shrink-0 duration-150 transition-font-size ease-in-out']"
                        aria-hidden="true"
                    />
                    <span
                        v-if="isSidebarLarge"
                        class="truncate duration-150 ease-in-out"
                    >{{ item.name }}</span>
                  </template>
                </SidebarItem>
              </li>
              <li v-if="!isSidebarLarge">
                <SDropdown
                  :items="navigationItems.addItems"
                  mode="hover"
                  :ui="{ width: 'w-44', base: 'ml-3' }"
                  :popper="{ placement: 'right' }"
                >
                  <span tabindex="0" :class="[[appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400 cursor-pointer'], 'group flex flex-col items-center rounded-lg text-xs font-normal transition-all duration-150 ease-in-out outline-none focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400', 'w-12 h-12 justify-center']">
                    <fa
                      icon="fa-regular fa-plus"
                      :class="['text-lg']"
                    />
                  </span>
                </SDropdown>
              </li>
              <li v-if="!isSidebarLarge">
                <UTooltip
                  text="Search"
                  :popper="{ placement: 'right' }"
                  :prevent="isSidebarLarge"
                  class="flex flex-col"
                  :shortcuts="[metaSymbol, 'K']"
                >
                  <span tabindex="0"
                    :class="[[appConfig.ui.button.color.white.ghost, '!text-gray-500/80 hover:!text-gray-600 dark:!text-gray-500 dark:hover:!text-gray-400 cursor-pointer'], 'group flex flex-col items-center rounded-lg text-xs font-normal transition-all duration-150 ease-in-out outline-none focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400', 'w-12 h-12 justify-center']"
                    @click="setState('commandPalette')"
                    @keyup.enter="setState('commandPalette')"
                    @keydown.space="setState('commandPalette')"
                  >
                    <fa
                      icon="fa-regular fa-magnifying-glass"
                      :class="['text-lg']"
                    />
                  </span>
                </UTooltip>
              </li>
            </ul>
          </li>
          <li>
            <div
              v-if="isSidebarLarge"
              class="text-xs font-medium leading-6 text-gray-400/90 dark:text-gray-600/90 mb-2"
            >
              Accounts
            </div>
            <ul
              role="list"
              class="space-y-1"
            >
              <!-- Account list -->
              <li
                v-for="account in navigationItems.accounts"
                :key="account.id"
              >
                <SidebarItem
                    :item="account"
                    :active="account.current"
                    :isSidebarLarge="isSidebarLarge">
                  <template #default="{active}">
                    <UKbd>{{ account.shortcuts[0] }}</UKbd>
                    <span
                        v-if="isSidebarLarge"
                        class="truncate duration-150 ease-in-out"
                    >{{ account.name }}</span>
                  </template>
                </SidebarItem>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
